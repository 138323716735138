import { Link } from "gatsby"
import React from "react"

import logo from '../images/logoRed.svg';

const Header = () => (
    <header
        style={{
            background: `white`,
            marginBottom: `1.45rem`,
        }}
    >
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `1.45rem 1.0875rem`,
                borderBottom: `1px solid #323232`
            }}
        >
            <h1 style={{ margin: 0 }}>
                <Link to="/">
                    <img src={logo} alt='logo' />
                </Link>
            </h1>
        </div>
    </header>
)

export default Header
