/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"

import classnames from 'classnames';

import logo from '../images/logo.svg';


const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: 960,
                    padding: `0 1.0875rem 1.45rem`,
                }}
            >
                <main>{children}</main>
            </div>
            <footer style={{ marginTop: `2rem`, backgroundColor: '#0B0B0D' }}>
                <section className='footerSection'>
                    <img src={logo} className='App-logo-footer' alt='logo' />
                    <div className='row'>
                        <div className={classnames('col', 'footerCol')}>
                            <span className='footerTitle' onClick={() => window.open("mailto:hello@rummo.to?subject=Let's partner up&body=Hi. I would like to know more about Rummo's partner program. Thank you.")}>
                                Become a partner
                                </span>
                            <span className='footerBody'>
                                Keep your fleet utilized and acquire new customers with Rummo
                                </span>
                        </div>
                        <div className={classnames('col', 'footerCol')}>
                            {/* <span className='footerTitle' onClick={() => window.open("mailto:hello@rummo.to?subject=Career opportunities at Rummo&body=Hi. I would like to know more about Rummo's career opportunities. Thank you.")}>
                                    Careers
                                </span> */}
                            <Link to="/careers/" className='footerTitle'>Careers</Link>
                            <span className='footerBody'>
                                Work at one of the fastest growing companies in the sector.
                                </span>
                        </div>
                    </div>
                    <span className='footerCopyright'>
                        Copyright © {new Date().getFullYear()} <Link style={{ color: 'white' }} to='/'>Rummo</Link> - All rights reserved.
                    {` `}
                    </span>
                </section>
            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
